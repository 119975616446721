/* -------------------- General Site layouts -------------------- **/
*{
	font-family: calibri, sans-serif;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}

@font-face {
    font-family: 'AmaticBold';
    src: url('../fonts/AmaticBold.eot');
    src: url('../fonts/AmaticBold.eot') format('embedded-opentype'),
         url('../fonts/AmaticBold.woff2') format('woff2'),
         url('../fonts/AmaticBold.woff') format('woff'),
         url('../fonts/AmaticBold.ttf') format('truetype'),
         url('../fonts/AmaticBold.svg#AmaticBold') format('svg');
}




/*** Page ***/
.PageResults,
.InnerPage{
	.banner{
		height: 66vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid $themeColor;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */ 
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #fff !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid $themeColor;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #dedede;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #d92d2a;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	}

	label{
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: $themeColor;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #71142b !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid $themeColor;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: $themeColor !important;

		 &:hover{
	    	    background-color: #4d4d4d !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: $themeColor !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: $themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #000;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid $themeColor; 
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid $themeColor;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid $themeColor;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 25%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		background-color: #fff;
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.InnerPage{
    .calPrev,
    .calNext {
        top: 37px;
    }
    .calendar-widget-table,
    .outerCalendarContainer{
        width: 100%;
    }
    .eventContainer{
        display: none !important;
    }
    .calHeader{
        .col-7,
        .col-5{
            width: 100%;
            text-align: center;
        }
        .col-5{
            display: none !important;
        }
    }
}
.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}


.SearchContainer{
    display: none;
    position: fixed;
    top: -4px;
    background-color: #dbb95a;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #dbb95a;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #dbb95a;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 






/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #9c1816;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #ca1916;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #ca1916;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	background-size: cover;
	background-position: center;

	.darken{
		@include stretchCover(rgba(0, 0, 0, 0.01), absolute, 1); 
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px; 
		left: 0px;

	background-size: cover;
	background-position: center;
	}
} 

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}


#google_translate_element{
    border: 30px solid #312d5e;
}

/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}
/*** Footer ***/
footer{
	padding: 20px 20px 20px 20px;
	border-top: 20px solid $themeColor;

}


/*** Menu ***/
#Menu{
	float: left;
    z-index: 9999;
    width: 100%;
    padding-right: 40px;
    text-align: center;
	display: inline-block;
	text-align: right;
	*{
		color: $themeColor;
		font-weight: 700;
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
    	text-align: right;
		position: relative;
		.LevelOne{
			display: inline-block; 
			text-align: center;
			position: relative;
			a{    
				font-size: 1em;
				@include transition($t1);
				padding: 14px 20px 24px 20px;
			    font-size: 1.1em;
			    line-height: 3em;
			    display: inline-block; 
	   	 		line-height: 1.1em; 
			}
		}
	}


	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 600px;
		height: 0px;
		font-size: 0.7em;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	text-align: left;
		position: absolute;
	    background-color: rgba(23, 125, 49, 0.84);
	    border-radius: 0px;
	    margin-top: 0px;
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) scale(0.98));

	    a{
	    	color: #fff !important;
		    display: inline-block;
		    padding: 10px 20px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
		    position: relative;
		    font-weight: bold;
			background-color: transparent;
	    	@include transition($t1);

	    	&:before{
	    		content:'';
	    		position: absolute;
	    		display: block;
	    		background-position: center;
	    		background-repeat: no-repeat;
	    		background-size: contain;
	    		background-image: url(../images/arrow.png);
	    		width: 10px;
	    		height: 10px;
	    		left: 5px;
	    		@include transition($t1);
	    		top: 50%;
	    		@include transform(translateY(-50%));
	    		opacity: 0;
	    	}
			&:hover:before{
				opacity: 1 !important;
			}
			&:hover{
    			background-color: rgba(255,255,255,0.2) !important;
			}
		}
		li{
			display: block;
			float: left;
			width: 50%;
		}
	}
	.SubMenu{
		left: 0%;
		@include transform(translateY(20px) scale(0.98));
	}
	.active .SubMenu{
		opacity: 1 !important;
		left: 0%;
		@include transform(translateY(0px) scale(1));
	}

	.alignRight{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) scale(0.98));
		}
	}

	.alignRight.active .SubMenu{
		opacity: 1 !important;
		left: 0%;
		@include transform(translateY(0px) scale(1));
	}
	


	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 51%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 5em;
    display: block;
    
    line-height: 1.2em;
opacity: 0.8;
    font-family: 'AmaticBold'; 
    font-family: "macho",sans-serif;

    *{

    	font-family: 'AmaticBold';
    	font-family: "macho",sans-serif;
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}
/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/

#header{
	position: fixed;
	z-index: 9999;
	width: 100%;
	top: 0px;
	padding-top: 50px;
	left: 0px;
	background-color: rgba(255,255,255,0.95);
	height: 109px;
}

.LevelOne{
	padding-left: 10px;
	padding-right: 10px;
}
.topMenuLink{
	display: inline-block !important;
	width: auto;
	padding-left: 0px !important;
	padding-right: 0px !important;
	position: relative;
	&:after{
		background-color: #ffdc1b;
		content: '';
		position: absolute;
		bottom: 10px;
		height: 3px;
		left: 0px;
		width: 100%;
		opacity: 0;
		@include transition($t1);
	}
	&:hover{
		&:after{
			opacity: 1 !important;
		}
	}
}
.active.LevelOne .topMenuLink{
	&:after{
		background-color: #ffdc1b;
		content: '';
		position: absolute;
		bottom: 10px;
		height: 3px;
		left: 0px;
		width: 100%;  
		opacity: 1;
		@include transition($t1);
	}
}
.currentLink{
	&:after{
		opacity: 1;
	}
}
#home2{
	padding: 100px 20px;

	z-index: 2;
	
	.contentLeft{
		padding: 20px;
		padding-left: 12%;
	}
	.contentRight{
		padding: 20px;
		padding-right: 5%;
	}
	.inner{
		width: 1000px;
	}
	.pageTitle{
		color: #1b4d3b;
    	font-family: 'AmaticBold'; 
    	font-family: "macho",sans-serif;
    	/*font-size: 5em;
    	font-size: 4em; */
    	font-size: 2.5em;

	}
}

.TopImage,
.imageCircle{
	display: inline-block;
	width: 100%;
	padding-bottom: 100%;
	border: 3px solid #ffdc1b;
	@include border-radius(50%);
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	.more{
		position: absolute;
		top: 50%;
		left: 50%;
		@include transition($t1);
		opacity: 0;
		@include transform($center);
		*{
		}
	}
}

.TopImage{
	margin: 50px 30px;
	width: 300px;
	height: 300px;
	padding-bottom: 0px;
}

.topLinks{
	position: absolute;
	top: 10px;
	right: 40px;
	display: inline-block;
	*{
		display: inline-block;
		vertical-align: middle;
	}
	a{
		font-size: 1.2em;
		color: $themeColor;
		font-weight: 700;
	}
	.searchToggle{
		cursor: pointer;
		margin-left: 20px;
		width: 25px;
	}
}

.more{
	img{
		margin-right: 10px;
		width: 25px;
	}
	font-size: 1.3em;
	*{
		display: inline-block;
		vertical-align: middle;
		color: #0d3d89;
	}
}
#Home3{
	background-color: rgba(20, 149, 210, 1);
	padding: 100px 20px;
	text-align: center;
	.bg{
		position: absolute;
		background-size: cover;
		background-position: center;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0.1;
		background-attachment: fixed;
		-webkit-background-attachment: fixed;
		background-image: url(../images/bg2.jpg);
	}
	.col-4{
		padding: 20px 5%; 
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
		@include transform(scale(1));
		@include transition($t1);

		.more{
			width: 100%;
		}
		.greenBg{
			background-color: rgba(255, 255, 255, 0.6);
			opacity: 0;	
			@include transition($t1);
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
		&:hover{
			.more{
				opacity: 1 !important;
			}
			.greenBg{
				opacity: 1 !important;
			}
		}
	}

	.imageCircle{
		border-color: #fff;
		overflow: hidden;
		position: relative;
	}
	.pageTitle{
		color: #fff;
    	font-family: 'AmaticBold'; 
    	font-family: "macho",sans-serif;
    	font-size: 5em;
    	font-size: 4em;
	}
	.inner{
		width: 1000px;
	}
	.NewsTitle{
		margin-top: 30px;
		font-weight: 700;
		line-height: 1.3em;
		font-size: 1.3em;
		color: #FFF;
	}
}
#Home4{
	z-index: 3;
	padding: 100px 20px 100px 20px;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
	background-attachment: fixed;
	-webkit-background-attachment: fixed;

	.whiteBg{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0.9;
    	background-color: #eae7e7;
	}

	.pageTitle{
		color: #1b4d3b;
    	font-family: 'AmaticBold'; 
    	font-family: "macho",sans-serif;
    	font-size: 2em !important;
    	font-size: 4em;
    	padding-bottom: 30px;
	}

	.inner{
		width: 1000px;
	}
	.col-4{
		padding: 20px 2%; 
		text-align: left;
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	.InnerEvent{
		padding: 30px;
		background-color: #FFF;
		@include border-radius(30px);
	}
	.EventDate{
		font-size: 1.5em;
		color: #40404b;
		padding-bottom: 10px;
		font-weight: 700
	}
	.EventText{
		.EventTitle{text-transform: uppercase;}
		border-top: 1px solid #43434d;
		border-bottom: 1px solid #43434d;
		padding:10px 0px;
		*{
			color: #21873c;
		}
	}
	.add{
		float: right;
		display: block;
	}

	.col-4{
		@include transition($t1);
		@transform(scale(1));
		&:hover{
			@include transform(scale(1.1));
		}
	}
}

#Home5{
	background-color: #0d3d89;
	padding: 10px;
	span{
		display: inline-block;
		padding: 0px 40px;
		vertical-align: middle;

    	font-family: "macho",sans-serif;
		color: #fff;

		font-size: 3.5em;
	}
}
.up{
	width: 100px;
	@include transition($t1);
	@include transform(scale(1));
	&:hover{
		@include transform(scale(1.2));
	}
}


.sideLinks{
	position: absolute;
	z-index: 999;
	right: 0px;
	top: 50%;
	@include transform(translateY(-50%));
	a{
		display: block;
		clear: both;
		float: right;
		background-color: $themeColor;
		padding: 20px;
		color: #fff;
		font-weight: 700;
		font-size: 1.4em;
		max-width: 160px;
    	line-height: 1em;
		margin: 6px 0px;
		min-width: 160px;
		@include transition($t1);
		&:hover{
			min-width: 180px;
			padding: 20px 40px 20px 20px;
		}
	}
	/*.link-1{
		background-color: #c0d767;
	}
	.link-2{
		background-color: #21873c;
	}
	.link-3{
		background-color: #a0ca29;
	}
	.link-4{
		background-color: #25ac48;
	}
	.link-5{
		background-color: #c0d767;
	}
	.link-6{
		background-color: #72be4e;
	}*/
}


a.important{
	color: #c00;
	font-weight: bold;
}

#tickerContainer{
	clear: both;
	position: relative;
	bottom: 0;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 46px;
}



.tickertape{
	float: left;
	position: relative;
	overflow: hidden;
}
 
.tickertape section,
.tickertape span{

}

.tickertape a{
	color: #fff;

}

.tickertape section{
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 15px 0;
	position: relative;
	text-align: center;
	color: #fff;
	letter-spacing: 1px;
	font-size: 1em;
}

.ticker{
	z-index: 900 !important; 
	position: absolute;
	bottom: 0px;
	top: auto;
	max-height: 46px;
	width: 100%;
	background-color: $themeColor;
}

#LatestNews{
	position: absolute;
    left: 0px;
    display: none;
    padding-left: 10px;
    background: #1eabe4;
    height: 46px;
    color: #fff;
    z-index: 2;
    line-height: 45px;
    font-size: 1.6em;
    text-transform: uppercase;
    padding-right: 20px;
}

.overlay1{
	position: absolute;
	bottom: 46px;
	width: 100%;
	left: 0px;
	max-height: 200px; 
	z-index: 99;
}

.overlay2,
.overlay3{
	position: absolute;
	bottom: 70px;
	z-index: 99;
}
.overlay2{
	left: 5vw;
	width: 20vw;
}
.overlay3{
	right: 5vw;
	bottom: 80px;
	width: 20vw;
}

.overlay4{
	position: absolute;
	bottom: -16px;
	left: 50%;
	@include transform(translateX(-50%));
	width: 60%;
}
.InnerPage{
	#header{
		background-color: #fff;
	}
	.pageTitle{
		color: #0d3d89;
		font-family: 'AmaticBold';
		font-family: "macho",sans-serif;
		font-size: 5em;
		font-size: 4em;
	}
}

.PageContainer{
	margin-top: 109px;
	background-color: #fff;
	padding-bottom: 40px;
	z-index: 9;
	.OverlayImg1{
		position: absolute;
		bottom: 0px;
		width: 100%;
	}
}

#breadcrumbs{
	padding: 10px 20px;
	text-align: center;
	background-color: $themeColor;
	*{
		color: #fff;
	}
}

.here{
	font-weight: bold;
}


.FileUpload{
	overflow: hidden;
	cursor: pointer;
	@include transition($t1);
	display: inline-block;
	width: 100%;
	max-width: 500px;
	padding: 10px 20px;
	text-transform: uppercase;
	border: 2px solid $themeColor;
	color: #2b2b2b;
	color: $themeColor !important;
	padding-left: 50px;
	position: relative;
	*{
		color: $themeColor;
	}
	&:before{
		@include transition($t1);
		content: '\f019';
		display: block;
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		opacity: 0;
		left: 10px;
			color: $themeColor;
		*{
			color: $themeColor;
		}
		@include transform(translateY(-100%));
	}
	&:after{
		@include transition($t1);
		display: block;
		content: '\f0f6';
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		left: 10px;
		opacity: 1;
			color: $themeColor;
		*{
			color: $themeColor;
		}
		@include transform(translateY(-50%));
	}
	&:hover{

			color: $themeColor;
		border: 2px solid $themeColor;
		&:before{
			color: $themeColor;
			opacity: 1;
			@include transform(translateY(-50%));
		}
		&:after{
			color: $themeColor;
			opacity: 0;
			@include transform(translateY(50%));
		}
	}

	.fileIcon{
		display: none;
	}
}
.fse{
	margin-top: 10px;
    font-size: 0.9em;
    *{
   	 font-size: 1em;
    }
}

.SearchContainer{
	display: none;
	position: absolute;
	top: 66px;
    right: 0px;
    @include transform(translateY(100%))
    background-color: #e0e0e0;
    padding: 7px;
    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    input[type=submit]{
	    background-color: $themeColor;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    i{
	    	color: #fff !important;
	    }
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
    }
}

#__toolbox-opener {
    z-index: 99991;
}



.pageList{
	a{
		display: block;
		margin-bottom: 5px;
		background-color: #eee;
		padding: 10px 20px;
		color: #0d3d89;
		@include transition($t1);
		border: 2px solid #0d3d89;
		&:hover{
			color: #fff !important;
		} 
	}
}


#MemberLoginForm_LoginForm{
	max-width: 500px; 
} 

.error{
    display: inline-block;
    float: left;
    clear: both;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: 1px solid #c70000;
    color: #c70000;
    background-color: #ffe7e7;
}
label {
    display: block;
    float: left;
    clear: both;
    width: 100%;
}






.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}


.HomePage{
	.calEventPopup{
		display: none !important;
	}
}



.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}




/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/
.calendar-widget-table tbody td{
	background-color: transparent;
}
.calendar-widget-table {
	width: 100%;
	margin:0 0 2em;
	*{
		text-align: center;
	}
	thead {
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				    font-size: 1em;
				.show-month, .next, .prev {
					display:inline-block;
					color: $themeColor;
					font-weight: bold;
					font-weight: lighter;
    				font-size: 1.3em;
					i {
						color: #fff;
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
				}
				.show-month {
					padding: 0 20px;
				}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color: $themeColor;
			border:none !important;
			font-size: 0.7em;
    padding-top: 40px !important;
		}
		*{
			text-transform: uppercase;
		}
	}
	tbody{
		.calendar-day {
			border: 1px solid transparent;
			color: #a9afb9;
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
				background-image: none !important;
				padding: 20px 0px !important;
				z-index: 1;
				text-align: center !important;

			border: 1px solid transparent;
				.calEventPopup {
					position: absolute;
					z-index: 80 !important;
					padding:20px;
					border:2px solid $LinkColor;
					background-color: #fff;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color:$LinkColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color:$LinkColor;
					}

				}
				&.hasEvent:hover {
						z-index: 90 !important;
					.calEventPopup {
						display: block;
						z-index: 90 !important;
					}
				}
				&:nth-child(1) .calEventPopup { margin-left:0; }
				&:nth-child(2) .calEventPopup { margin-left:-20px; }
				&:nth-child(3) .calEventPopup { margin-left:-40px; }
				&:nth-child(4) .calEventPopup { margin-left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { margin-right:-40px; }
				&:nth-child(6) .calEventPopup { margin-right:-20px; }
				&:nth-child(7) .calEventPopup { margin-right:0px; }
			}
		}

		.today {
			background-color: #40404b !important;
			color: #fff !important;
		}
		
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
		}

		.hasEvent {
			background-color: $themeColor;
			background-repeat: no-repeat;
			background-position: 5px 5px;
			background-size: 4px 4px;
			color: #fff !important;
			&:hover{
			
			background-color: #40404b !important;

			color: #fff !important;
			}

		}
	} 
	.calendar-day:hover, .selected {
		
			background-color: #40404b !important;

			color: #fff !important;
		font-weight: 500;
	}
}

.calendarWidget{
    padding: 10px;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px;
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(10px);
    margin-bottom: 10px;
    background-color: #cfcfcf;
    float: left;
    width: 100%;
    font-size: 0.9em;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}

	*{
		color: $themeColor;
	}

    @include transition(background 0.35s);
    display: block;
    
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;

		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}
 
.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
	}
	.url{
		padding: 10px;
    	color: #333 !important;
    	background-color: #166119;
    	@include transition($t1);
    	&:hover{
    		background-color: #4d4d4d !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

.calendar-widget-table thead tr th {
    padding: 10px 20px 10px 20px;
}

.calendar-widget-table thead tr th .show-month{
	font-size: 1.2em !important;
}


#HomepageEvents ul li,
#allEvents ul li{
	position: relative;
}
#allEvents ul li .toggleBtn, #HomepageEvents ul li .toggleBtn {
 	padding: 0px !important;
    float: left;
    width: 100%;
}

.calday{
	position: absolute;
    top: 10px;
    left: 19px;
}
.eventTitle{
	padding-left: 110px;
	padding-right: 35px;
	width: 100% !important;
}

.bee1{
	position: absolute;
	bottom: 190px;
	right: 100px;
	width: 40px;
	z-index: 990;
}
.butterfly1{
position: absolute;
	bottom: 250px;
	left: 100px;

	width: 50px;
	z-index: 990;
}
.ladybird1{
	position: absolute;
	bottom: 90px;
	left: 30%;
	width: 45px;
	z-index: 990;
}

.bee2{
	position: absolute;
    bottom: 190px;
    left: 16%;
    width: 52px;
    z-index: 990;
}
.butterfly2{
    position: absolute;
    bottom: 159px;
    right: 37%;
    width: 63px;
    z-index: 990;
    @include transform(rotate(21deg));
}
.ladybird2{
	position: absolute;
    bottom: 20px;
    right: 31%;
    width: 45px;
    z-index: 990;
}
@media all and (max-width: 1220px)  {
	.ladybird1{
	bottom: 70px;
	}
}
@media (max-width: 1100px){
	.ladybird2{ 
		    width: 3%;
	    bottom: 46px;
	}
}

@media (max-width: 1100px){
	.overlay4,
	.bee2,
	.butterfly2,
	.ladybird2{
		display: none;
	}
}

.Hedgehog1{
	position: absolute;
	bottom: -5px;
	width: 140px;
	left: 20%;
	 animation: Hedgehog 17s linear 0s infinite;
	-o-animation: Hedgehog 17s linear 0s infinite;
	-ms-animation: Hedgehog 17s linear 0s infinite;
	-moz-animation: Hedgehog 17s linear 0s infinite;
	-webkit-animation: Hedgehog 17s linear 0s infinite;
}
@keyframes Hedgehog {
    0%   { @include transform(rotate(-10deg) rotateY(180deg));left: 20%;}
    10%   { @include transform(rotate(10deg) rotateY(180deg));}
    20%   { @include transform(rotate(-10deg) rotateY(180deg));}
    30%   { @include transform(rotate(10deg) rotateY(180deg));}
    40%   { @include transform(rotate(-10deg) rotateY(180deg));}
    50%   { @include transform(rotate(10deg) rotateY(0deg));left: 14%;;}
    60%   { @include transform(rotate(-10deg) rotateY(0deg));}
    70%   { @include transform(rotate(10deg) rotateY(0deg));}
    80%   { @include transform(rotate(-10deg) rotateY(0deg));}
    90%   { @include transform(rotate(10deg) rotateY(0deg));}
    100% { @include transform(rotate(-10deg) rotateY(180deg));left: 20%;;}
}

/*.PageLoaded{
	.Hedgehog1{
		left: 20% !important;
	}
	.otter1{
		right: 20% !important;
	}
}*/

.topImage1{
	border-color: #0392d2;
}
.topImage2{
	border-color: #fe0400;
}
.topImage3{
	border-color: #1ac74c;
}
.topImage4{
	border-color: #ffdc1b;
}
.topImage5{
	border-color: #0392d2;
}
/* -------------------- Site Build -------------------- **/


/* -------------------- Scene One -------------------- **/
.animalSceneOne{
	position: absolute;
	bottom: 46px;
	width: 1400px;
	max-width: 100%;
	left: 50%;
	z-index: 100;
	@include transform(translateX(-50%));
	.outline2{
		width: 100%;
	}

	.Squirrel{
		bottom: 30px;
		left: 100px;
	}

}
.Squirrel{
	position: absolute;
	bottom: 30px;
	position: absolute;
    bottom: 30px;
    width: 150px;
    height: 140px;
    left: 100px;

	.tail{
		position: absolute;
		z-index: 3;
		position: absolute;
	    z-index: 3;
	    width: 80px;
	    bottom: 3px;
	    left: 11px;
	    @include transform(rotate(10deg));
	    transform-origin: 100% 100%;
	    -o-transform-origin: 100% 100%;
	    -ms-transform-origin: 100% 100%;
	    -moz-transform-origin: 100% 100%;
	    -webkit-transform-origin: 100% 100%;
	    animation: Squirrel 3s linear 0s infinite alternate;
	    -o-animation: Squirrel 3s linear 0s infinite alternate;
	    -ms-animation: Squirrel 3s linear 0s infinite alternate;
	    -moz-animation: Squirrel 3s linear 0s infinite alternate;
	    -webkit-animation: Squirrel 3s linear 0s infinite alternate;
	}
	.body{
		position: absolute;
		position: absolute;
	    width: 81px;
	    bottom: 0px;
	    right: 14px;
	    z-index: 9;
	}
}

@keyframes Squirrel {
    0%   { @include transform(rotate(10deg));}
    90% { @include transform(rotate(10deg));}
    100% { @include transform(rotate(-10deg));}
}


.Badger{
	position: absolute;
    bottom: 30px;
    width: 149px;
    height: 206px;
    left: 257px;
    .body{
    	position: absolute;
	    bottom: 0px;
	    left: 4%;
	    width: 87%;
	    bottom: 4%;
    }
    .head{
    	position: absolute;
	    bottom: 49%;
	    width: 95%;
	    left: 3%;
    	transform-origin: 50% 100%;
	    -o-transform-origin: 50% 100%;
	    -ms-transform-origin: 50% 100%;
	    -moz-transform-origin: 50% 100%;
	    -webkit-transform-origin: 50% 100%;
	    animation: Badger 7s linear 0s infinite alternate;
	    -o-animation: Badger 7s linear 0s infinite alternate;
	    -ms-animation: Badger 7s linear 0s infinite alternate;
	    -moz-animation: Badger 7s linear 0s infinite alternate;
	    -webkit-animation: Badger 7s linear 0s infinite alternate;
    }
}
@keyframes Badger {
    0%   { @include transform(rotate(-10deg));}
    50%   { @include transform(rotate(-10deg));}
    60%   { @include transform(rotate(10deg));}
    100% { @include transform(rotate(10deg));}
}

.butterfly{
	position: absolute;
    left: 66px;
    bottom: 165px;
    width: 67px;
	-o-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
    animation: Butterfly 7s linear 0s infinite;
	-o-animation: Butterfly 7s linear 0s infinite;
	-ms-animation: Butterfly 7s linear 0s infinite;
	-moz-animation: Butterfly 7s linear 0s infinite;
	-webkit-animation: Butterfly 7s linear 0s infinite;
} 
@keyframes Butterfly {
     0%   { @include transform(rotate(-10deg));bottom: 165px;}
    10%   { @include transform(rotate(10deg));}
    20%   { @include transform(rotate(-10deg));}
    30%   { @include transform(rotate(10deg));}
    40%   { @include transform(rotate(-10deg));}
    50%   { @include transform(rotate(10deg));bottom: 365px;}
    60%   { @include transform(rotate(-10deg));}
    70%   { @include transform(rotate(10deg));}
    80%   { @include transform(rotate(-10deg));}
    90%   { @include transform(rotate(10deg));}
    100%  { @include transform(rotate(-10deg));bottom: 165px;}
}

.ladybird{
	position: absolute;
	width: 40px;
    left: 426px;
    bottom: 37px;
	-o-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
    animation: LadyBird 7s linear 0s infinite;
	-o-animation: LadyBird 7s linear 0s infinite;
	-ms-animation: LadyBird 7s linear 0s infinite;
	-moz-animation: LadyBird 7s linear 0s infinite;
	-webkit-animation: LadyBird 7s linear 0s infinite;
}

@keyframes LadyBird {
    0%   { @include transform(rotate(-10deg) rotateY(0deg));left: 476px;}
    10%   { @include transform(rotate(10deg) rotateY(0deg));}
    20%   { @include transform(rotate(-10deg) rotateY(0deg));}
    30%   { @include transform(rotate(10deg) rotateY(0deg));}
    40%   { @include transform(rotate(-10deg) rotateY(0deg));}
    50%   { @include transform(rotate(10deg) rotateY(180deg));left: 376px;}
    60%   { @include transform(rotate(-10deg) rotateY(180deg));}
    70%   { @include transform(rotate(10deg) rotateY(180deg));}
    80%   { @include transform(rotate(-10deg) rotateY(180deg));}
    90%   { @include transform(rotate(10deg) rotateY(180deg));}
    100% { @include transform(rotate(-10deg) rotateY(0deg));left: 476px;}
}

.LeftScene{
	position: absolute;
	height: 100%;
	left: 0px;
	width: 500px;
	bottom: 0px; 
}

.RightScene{
	position: absolute;
	height: 100%;
	right: 0px;
	width: 500px;
	bottom: 0px; 
}


.Fox{
	position: absolute;
    width: 200px;
    height: 228px;
    bottom: 30px;
    right: 287px;
	.body{
		position: absolute;
	    bottom: 13px;
	    width: 95px;
	    left: 68px;
	}
	.tail{
	    position: absolute;
	    bottom: 35px;
	    width: 71px;
	    left: 7px;
	    @include transform(rotate(-10deg));
	    transform-origin: 100% 100%;
	    -o-transform-origin: 100% 100%;
	    -ms-transform-origin: 100% 100%;
	    -moz-transform-origin: 100% 100%;
	    -webkit-transform-origin: 100% 100%;
	    animation: FoxTail 4s linear 0s infinite alternate;
	    -o-animation: FoxTail 4s linear 0s infinite alternate;
	    -ms-animation: FoxTail 4s linear 0s infinite alternate;
	    -moz-animation: FoxTail 4s linear 0s infinite alternate;
	    -webkit-animation: FoxTail 4s linear 0s infinite alternate;
	}

	.head{
		position: absolute;
		bottom: 110px;
		width: 148px;
		left: 46px;
	    transform-origin: 50% 100%;
	    -o-transform-origin: 50% 100%;
	    -ms-transform-origin: 50% 100%;
	    -moz-transform-origin: 50% 100%;
	    -webkit-transform-origin: 50% 100%;
	    @include transform(rotate(10deg));
	     animation: FoxHead 10s linear 0s infinite alternate;
	    -o-animation: FoxHead 10s linear 0s infinite alternate;
	    -ms-animation: FoxHead 10s linear 0s infinite alternate;
	    -moz-animation: FoxHead 10s linear 0s infinite alternate;
	    -webkit-animation: FoxHead 10s linear 0s infinite alternate;
	}
}


@keyframes FoxTail {
    0%   { @include transform(rotate(-10deg));}
    100% { @include transform(rotate(10deg));}
}

@keyframes FoxHead {
0%   { @include transform(rotate(10deg));}
    50%   { @include transform(rotate(10deg));}
    60%   { @include transform(rotate(-10deg));}
    100% { @include transform(rotate(-10deg));}
}



.Rabbit{
    position: absolute;
    width: 95px;
    height: 176px;
    bottom: 75px;
    right: 187px;
	.body{
		position: absolute;
	    bottom: 10px;
	    width: 83px;
	    left: 5px;
	}
	.head{
	    position: absolute;
	    bottom: 70px;
	    width: 83px;
	    left: 5px;
	    transform-origin: 50% 100%;
	    -o-transform-origin: 50% 100%;
	    -ms-transform-origin: 50% 100%;
	    -moz-transform-origin: 50% 100%;
	    -webkit-transform-origin: 50% 100%;
	    @include transform(rotate(-10deg));
	     animation: RabbitHead 5s linear 0s infinite alternate;
	    -o-animation: RabbitHead 5s linear 0s infinite alternate;
	    -ms-animation: RabbitHead 5s linear 0s infinite alternate;
	    -moz-animation: RabbitHead 5s linear 0s infinite alternate;
	    -webkit-animation: RabbitHead 5s linear 0s infinite alternate;
	}
}


@keyframes RabbitHead {
    0%   { @include transform(rotate(-10deg));}
    100% { @include transform(rotate(10deg));}
}



.Bee{
	position: absolute;
    right: 102px;
    bottom: 139px;
    width: 46px;
	-o-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-webkit-transform-origin: 50% 50%;
    animation: Bee 7s linear 0s infinite;
	-o-animation: Bee 7s linear 0s infinite;
	-ms-animation: Bee 7s linear 0s infinite;
	-moz-animation: Bee 7s linear 0s infinite;
	-webkit-animation: Bee 7s linear 0s infinite;
} 
@keyframes Bee {
     0%   { @include transform(rotate(-10deg));bottom: 205px;}
    10%   { @include transform(rotate(10deg));}
    20%   { @include transform(rotate(-10deg));}
    30%   { @include transform(rotate(10deg));}
    40%   { @include transform(rotate(-10deg));}
    50%   { @include transform(rotate(10deg));bottom: 139px;}
    60%   { @include transform(rotate(-10deg));}
    70%   { @include transform(rotate(10deg));}
    80%   { @include transform(rotate(-10deg));}
    90%   { @include transform(rotate(10deg));}
    100%  { @include transform(rotate(-10deg));bottom: 205px;}
}

.Mole{
	width: 126px;
    bottom: 43px;
    position: absolute;
    right: 64px;
     /*animation: MoleTwo 11s linear 0s infinite;
	-o-animation: MoleTwo 7s linear 0s infinite;
	-ms-animation: MoleTwo 7s linear 0s infinite;
	-moz-animation: MoleTwo 7s linear 0s infinite;
	-webkit-animation: MoleTwo 7s linear 0s infinite;*/
}

@keyframes MoleTwo {
    0%   { @include transform(rotate(-10deg) rotateY(0deg));right: 64px;}
    10%   { @include transform(rotate(10deg) rotateY(0deg));}
    20%   { @include transform(rotate(-10deg) rotateY(0deg));}
    30%   { @include transform(rotate(10deg) rotateY(0deg));}
    40%   { @include transform(rotate(-10deg) rotateY(0deg));}
    50%   { @include transform(rotate(10deg) rotateY(180deg));right: 104px;}
    60%   { @include transform(rotate(-10deg) rotateY(180deg));}
    70%   { @include transform(rotate(10deg) rotateY(180deg));}
    80%   { @include transform(rotate(-10deg) rotateY(180deg));}
    90%   { @include transform(rotate(10deg) rotateY(180deg));}
    100% { @include transform(rotate(-10deg) rotateY(0deg));right: 64px;}
}



.animalSceneTwo{
	position: absolute;
	bottom: -7px;
    min-width: 1400px;
    max-width: 100%;
	left: 50%;
	z-index: 100;
	@include transform(translateX(-50%));
	.outline1{
		width: 100%;
	}

	.Fox {
	    bottom: 8px;
	    left: 200px;
	}
	.Squirrel{
	    bottom: 16px;
   		left: 368px;
	}
	.Bee{
		right: auto;
    	left: 167px; 
	}
	.hedgehog{
		position: absolute;
	    width: 107px;
	    bottom: 20px;
	    left: 506px;
	}
	.Badger {
	    bottom: 13px;
	    left: 612px;
	}
	.Rabbit {
	    bottom: 12px;
	    left: 766px;
	}
	.butterfly{
		right: 411px;
    	left: auto;
    	margin-bottom: -53px;
	}
	.ladybird {
		margin-left: 600px;
	    z-index: 8;
	    bottom: 20px;
	}
	.otter{
		position: absolute;
		bottom: -9px;
		width: 180px;
		left: 1053px;
	}
	
	.Mole{
		width: 126px;
	    margin-bottom: -25px;
	    position: absolute;
	    left: 895px;
	   /* animation: Mole 7s linear 0s infinite;
		-o-animation: Mole 7s linear 0s infinite;
		-ms-animation: Mole 7s linear 0s infinite;
		-moz-animation: Mole 7s linear 0s infinite;
		-webkit-animation: Mole 7s linear 0s infinite;*/
	}

	@keyframes Mole {
	    0%   { @include transform(rotate(-10deg) rotateY(0deg));left: 895px;}
	    10%   { @include transform(rotate(10deg) rotateY(0deg));}
	    20%   { @include transform(rotate(-10deg) rotateY(0deg));}
	    30%   { @include transform(rotate(10deg) rotateY(0deg));}
	    40%   { @include transform(rotate(-10deg) rotateY(0deg));}
	    50%   { @include transform(rotate(10deg) rotateY(180deg));left: 845px;}
	    60%   { @include transform(rotate(-10deg) rotateY(180deg));}
	    70%   { @include transform(rotate(10deg) rotateY(180deg));}
	    80%   { @include transform(rotate(-10deg) rotateY(180deg));}
	    90%   { @include transform(rotate(10deg) rotateY(180deg));}
	    100% { @include transform(rotate(-10deg) rotateY(0deg));left: 895px;}
	}

}

.bannerText{
    z-index: 9;
    font-family: 'AmaticBold';
    font-family: "macho",sans-serif;
    color: #fff;
    font-size: 10em;
    opacity: 0.8;
    font-size: 7em;
    position: absolute;
    top: 45%;
    left: 50%;
	@include transform($center);
	width: 80%;
}
.contentPage table{
	border-collapse: collapse;
    border: 1px solid #000;
    margin-bottom: 15px;
    }
.contentPage table th,
.contentPage table td{
padding: 10px 5px !important;
	border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #000;
}

.contentPage table th{
	background-color:rgba(43,43,43 ,0.01);
} 

.ofstedLogo{
	width: 150px;
}

.otterAnimated{
	position: absolute;
    width: 184px;
    height: 152px;
    left: 1051px;
    bottom: -11px;
	.body{
		position: absolute;
	    left: 1px;
	    width: 173px;
	    bottom: 29px;
	    z-index: 2;
	}
	.tail{
	    z-index: 1;
	    position: absolute;
	    bottom: 1px;
	    width: 52px;
	    left: 128px;

	    transform-origin:  100% 0%;
	    -o-transform-origin:  100% 0%;
	    -ms-transform-origin:  100% 0%;
	    -moz-transform-origin:  100% 0%;
	    -webkit-transform-origin: 100% 0%;

	    animation: OtterTail 5s linear 0s infinite alternate;
	    -o-animation: OtterTail 5s linear 0s infinite alternate;
	    -ms-animation: OtterTail 5s linear 0s infinite alternate;
	    -moz-animation: OtterTail 5s linear 0s infinite alternate;
	    -webkit-animation: OtterTail 5s linear 0s infinite alternate;
	}
}

@keyframes OtterTail {
    0%   { @include transform(rotate(-10deg));}
    100% { @include transform(rotate(10deg));}
}
.otter1{
	position: absolute;
	bottom: -34px;
	width: 200px;
	right: 20%;
}
/* -------------------- Scene One -------------------- **/


.footerParagraph{
	font-size: 0.8em;
	line-height: 1.7em;
}


.EventTitle{
	font-weight: bold;
	border-bottom: 2px solid #fff;
}

.EventStartTime{
	border-bottom: 2px solid #fff;
}

.calendarContent{
	p{
		margin-bottom: 0px !important;
	}
}

.ClockIcon{
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-right: 10px;
	background-size: contain;
	background-repeat: no-repeat;
	vertical-align: middle;
	background-image: url(../images/clock.png);
}
.AddToCal{
	display: inline-block;
	width: 20px;
	height: 20px;
	position: absolute;
	right: 0px;
	top: 45%;
	background-size: contain;
	background-repeat: no-repeat;
	vertical-align: middle;
	background-image: url(../images/add.png);
	@include transition(all 100ms);
	@include transform(scale(1) translateY(-50%));
	&:hover{
		@include transform(scale(1.2) translateY(-50%));
	}
}

.EventTitle{
	padding-bottom: 5px;
}
.calendarContent{

	padding-bottom: 5px;
}
.EventStartTime{

	padding-bottom: 5px;
}

.List{
	padding-left: 3%;
}
.Cal{
	padding-right: 3%;
}


.calendar-widget-table thead tr th .show-month {
	display: block;
    text-align: left;
    padding: 0px;
    font-weight: bold !important;
    text-transform: uppercase;
}
.calendar-widget-table thead tr th{
	padding: 0px 10px !important;
}


.calendar-widget-table thead tr th .prev,
.calendar-widget-table thead tr th .next{
    position: absolute;
    width: 29px;
    height: 29px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    top: -10px;
    @include transition($t1);
    &:hover{
    	opacity: 1 !important;
    }
}

.calendar-widget-table thead tr th .prev{
	background-image: url(../images/calPrev.png);
	right: 50px;
}
.calendar-widget-table thead tr th .next{
	right: 10px;
	background-image: url(../images/calNext.png);
}


.OverlayLeft{
	position: absolute;
	top: 50%;
	@include transform(translateY(-50%));
	height: 95%;
	left: -10%;
	opacity: 0.1;
}
.OverlayRight{
	position: absolute;
	top: 50%;
	@include transform(translateY(-50%));
	height: 95%;
	right: -10%;
	opacity: 0.1;
}

.kite{
    max-height: 120px;
}

.bottomText{
	padding-top: 10px;
	font-size: 1em;
	line-height: 1.3em;
}


.ClassThumb{
	padding: 20px;
	text-align: center;
	img{
		height: 150px;
		@include transition(all 100ms);
		@include transform(scale(1));
		width: auto !important;
		max-width: none !important;
	}
	&:hover{
		img{

				@include transform(scale(1.2));
		}
	}
	font-size: 1.3em;
	a{ 
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.ClassLink{
	a{ 
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}
.ClassLinkInner{
	padding: 13px 20px;
		border: 2px solid $themeColor;
		@include transition(all 100ms);
	*{
		@include transition(all 100ms);
		text-align: left;
		@include transition(all 100ms);
		text-transform: uppercase;
	}
	&:hover{

		background-color: $themeColor;
	}
}
.ClassLinkInner.Red{
	border-color: #fe0400;
	&:hover{
		background-color: #fe0400;
		*{
			color: #fff;
		}
	}
}
.ClassLinkInner.Yellow{
	border-color: #ffe145;
	&:hover{
		background-color: #ffe145;
		*{
			color: #fff;
		}
	}
}
.ClassLinkInner.Blue{
	border-color: #34a5da;
	&:hover{
		background-color: #34a5da;
		*{
			color: #fff;
		}
	}
}
.ClassLinkInner.Green{
	border-color: #65d784;
	&:hover{
		background-color: #65d784;
		*{
			color: #fff;
		}
	}
}


.policyBar{
	padding: 10px;
	font-size: 0.7em;
	background-color: #2b2b2b;
	color: #fff;
	*{
	color: #fff;
	}
}

.cookieWarning{
	width: 100% !important;
}

footer{
	.divider{
		display: inline-block;
		padding: 0px 15px;
	}

	.col-10{
	font-size: 0.9em;
	}
}


.social{
	display: inline-block;
	padding: 0px;
	margin: 5px;
	font-size: 1.2em;
	i.fa-facebook{
		color: #4166b2;
	}
	i.fa-twitter{
		color: #1da1f2;
	}
}

.governorsLogin{
	margin-left: 15px;
}



#sidebar{
	.current + button{
		display: none;
	}
	.current + button + ul{
	background-color: #efefef;
		display: block !important;
	}
}

.toggleContent{
	*{
	color: #333;
	}
}
.toggleContent .url{
	color: #fff !important;
	margin-top: 10px;
	display: inline-block;
}

.calendar-widget-table tbody .hasEvent {
	position: relative;
}
/*
.Download-Link{
    color: #115844 !important;
    display: inline-block;
    border-radius: 7px;
    margin: 5px;
    background-color: #efefef;
    padding: 11px 40px 11px 10px;
    vertical-align: middle;
    border: 2px solid #115844;
    position: relative;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 38px;
    background-image: url(../images/uoploadIcon.png);
}*/


.Download-Link{
	overflow: hidden;
	cursor: pointer;
	@include transition($t1);
	display: inline-block;
	vertical-align: middle;
	padding: 10px 20px;
	text-transform: uppercase;
	border: 2px solid $themeColor;
	color: #2b2b2b;
	color: $themeColor !important;
	padding-left: 50px;
	position: relative;
}
.Download-Link	*{
		color: $themeColor;
}

.Download-Link:before{
		@include transition($t1);
		content: '\f019';
		display: block;
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		opacity: 0;
		left: 10px;
	}
	.Download-Link:before{
		transform: translateY(-100%);
		-o-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-webkit-transform: translateY(-100%);
			color: $themeColor;
	}
	.Download-Link:before *{

			color: $themeColor;
	}
.Download-Link:after *{

			color: $themeColor;
}
.Download-Link:after{
		@include transition($t1);
		display: block;
		content: '\f0f6';
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		left: 10px;
		opacity: 1;
			color: $themeColor;
	transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}
.Download-Link:hover{
	color: $themeColor;
		border: 2px solid $themeColor;
	}
.Download-Link:hover:before{
			color: $themeColor;
			opacity: 1;
			transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		}
.Download-Link:hover:after{
			color: $themeColor;
			opacity: 0;
			transform: translateY(50%);
		-o-transform: translateY(50%);
		-ms-transform: translateY(50%);
		-moz-transform: translateY(50%);
		-webkit-transform: translateY(50%);
		}
	


.ContentSection{
	padding: 20px 0px;
	border-bottom: 2px solid #efefef;
}



.fancybox-overlay {
    z-index: 999999 !important;
}
.fancybox-opened {
    z-index: 9999999 !important;
}



.translate{
	position: absolute;
    top: 18px;
    right: 10px;
    width: 22px;
}

.TranslateBG{
	background-color: rgba(0,0,0,0.76) !important;
}


#google_translate_element{
    border: 30px solid #2b2b2b !important;
}

font{
	color: inherit !important;
}
 


/*** Simple Menu CSS ***/
.LevelOneMenu{
	position: relative;
	display: block;
	text-align: right;
	font-size: 1em;
	box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	*{
		font-size: 1em;
		box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}
	li,ul{
		margin: 0px;
		padding: 0px;
	}
	*{
		color: #858584;
		font-weight: 600;
	}
}
.LevelOneMenuItem{
	display: inline-block;
	position: relative;
	text-align: center;
	vertical-align: middle;
	&:hover{
		.LevelOneMenuLink{color: #32a1d1 !important;}
		.LevelTwoMenu{
			display: block;
		}
	}
}
.LevelOneMenuLink{
	display: block;
	position: relative;
	width: 100%;
	float: left;
	clear: both;
	padding: 10px;
}
.LevelTwoMenu{
	position: absolute;
	display: none;
	left: 50%;
	min-width: 250px;
	bottom: 0px;
	background-color: #efefef;
	@include transform(translateX(-50%) translateY(100%));
}
.LevelTwoMenuItem{  
	display: block;
	position: relative;
	width: 100%;
	float: left;
}
.LevelOneMenuLink{
	display: block;
	position: relative;
	width: 100%;
	text-align: left;
	float: left;
	padding: 10px;
	@include transition($t1);
	background-color: rgba(0,0,0, 0);

}

.LevelTwoMenuLink{
	text-align: left;
	display: block;
	position: relative;
	width: 100%;
	float: left;
	clear: both;
	padding: 10px;
}

.LevelOneMenuLink:after {
    content: '';
    display: block;
    background-color: #000;
    height: 50%;
    width: 2px;
    right: 0px;
    top: 50%;
    position: absolute;
    display: none;
    transform: translateY(-50%);
}
.LevelOneMenu .LevelOneMenuItem:last-of-type .LevelOneMenuLink:after{
	display: none !important;
}

.WideMenu{
	position: static;
	.LevelOneMenuItem{
		position: static;
	}
	.LevelTwoMenu {
    	width: 100%;
    	padding: 20px 15%;
	}
	.LevelTwoMenuItem{
		width: 25%;
		display: block;
		float: left;
		clear: none;
	}
}
/*** Simple Menu CSS ***/
.col-2{min-width:16.66%;width: 16.66%;}
.col-3, .col-1-4{min-width:25%;width: 25%;}
.col-4, .col-1-3{min-width:33.33%;width: 33.33%;}
.col-5{min-width:41.66%;width: 41.66%;}
.col-6, .col-1-2 {min-width:50%;width: 50%; position: relative;}
.col-7{min-width:58.33%;width: 58.33%;}
.col-8{min-width:66.66%;width: 66.66%;}
.col-9{min-width:75%;width: 75%;}
.col-10{min-width:83.33%;width: 83.33%;}
.col-11{min-width:91.66%;width: 91.66%;}
.col-12, .col-2-2, .row{min-width:100%;width: 100%;}
.col-five, .col-1-5{width:20%;}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .row {
	position: relative;
	float: left;
}

#header{
	position: fixed;
	z-index: 9999;
	top: 0px;
	padding-top: 65px;
	background-color: rgba(255,255,255,1);
	border-bottom: 5px solid #0d3d89;
}
.searchIcon {
	background-image:url(../images/searchIcon.svg);
	background-position: center center;
	background-size:50% 50%;
	background-repeat: no-repeat;
	width:39px;
	height:39px;
	position:absolute;
	top:20px;
	right:20px;
	cursor: pointer;
	border:1px solid $LinkColor;
	display:inline-block;
}
.homebtn{
	position: absolute;
	top: 20px;
	left: 20px;
	img{
		height: 70px;
	}
}

.Facebook,
.twitter{
	position: absolute;
	top: 20px;
	right: 110px;
	font-size: 1.6em;
}
.twitter{
	right: 80px;
}
.HomeBannerBottom{
	position: absolute;
	bottom: 0px;
	left: 0px;
	min-height: 200px;
	width: 100%;
	background-color: rgba(48,48,48, 0.8);
	*{
		color: #fff;
	}
	.inner{
		width: 1600px;
		padding: 90px 45px 20px 45px;
		max-width: 100%;
		margin: 0 auto;
	}
}
.HomeTagLine{
	font-size: 2em; 
	text-align: center;
	padding-bottom: 15px;
	font-family: calibri, sans-serif;
}

.bottomDecor{
	background-color: #0d3d89;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	/*height: 100px;*/
	height: 7.6vw;
}
.slideInner{
	padding: 20px;
	&:hover{
.slideImg{

		@include transform(scale(1.1) !important);
	}
	}
}
.slideImg{
	padding-bottom: 100%;
	background-size: contain;
	background-position: center;
	background-color: transparent;
	/*border: 3px solid #0287cc;*/
	background-repeat: no-repeat;
	@incude transform(scale(1));
	@include transition(all 100ms);
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	
}
.flexslider .slides  li{
	height: 100vh;
}
.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100% !important;
}

header.windowHeight{
	float: left;
	clear: both;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}
.downArrow{
	position: absolute;
	top: 50px;
	left: 50%;
	@include transform($center);
	cursor: pointer;
	height: 40px;
}
.SliderOneNext{
	position: absolute;
	top: 50%;
	width: 40px;
	height: 40px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../images/ArrowRight.png);
	cursor: pointer;
	right: -30px;
	@include transform(translateY(-50%));
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}
.SliderOnePrev{
	position: absolute;
	top: 50%;
	width: 40px;
	height: 40px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../images/ArrowLeft.png);
	cursor: pointer;
	left: -30px;
	@include transform(translateY(-50%));
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}
.kite1{
	position: absolute;
    height: 1009px;
    right: 0px;
    top: 0px;
   	@include transform(translateY(-500px));
}
.kite2{
	position: absolute;
    height: 1009px;
    right: 0px;
    bottom: 0px;
   	@include transform(translateY(509px));
}





/*
|===============================================================
|	Welcome Message
|===============================================================
*/
#welcomeMessage {
	width:100%;
	position:relative;
	overflow:hidden;

	/*padding:90px 30px;*/
	padding-bottom: 40px;
	/*height:80vh;*/
	z-index: 98;
	.inner {
		width:100%;
		max-width:1200px;
		z-index:99;
		position:relative;
		padding:0 10px;
		padding-top: 100px;
		padding-left: 300px;
		.welcomeimg{
			border: 3px solid #32a1d1;
			position: absolute;
			height: 240px;
			width: 240px;
			background-size: cover;
			background-position: center;
			left: 10px;
			top: 100px;
			@include border-radius(50%);
		}
		color:$textGrey;
		font-size:20px;
		h1 {
			color:$textGrey;
			text-align: left;
			margin-bottom:40px;
		}
		li {
			list-style:disc;
			    margin: 0 0 0 20px;
		}
		.moreAboutUs {
			display:inline-block;
			border-top:2px solid $LinkColor;
			padding:20px;
			text-transform: uppercase;
			position:relative;
			/*left:50%;
			@include transform(translateX(-50%));*/
			margin:30px 0;
			&:hover {
			}
		}
	}
}

.slideUp {
	top:0 !important;
}

/*
|===============================================================
|	Home Page Footer - Mock
|===============================================================
*/

.homepageFooter {
	width: 100%;
	background-color: #0d3d89;
	border-color: #0d3d89;
	color:#FFFFFF;
	    position: relative;
	.inner {
		width:100%;
		max-width:1280px;
		margin:0 auto;
		.footerLeft {
			width:calc(50% - 20px);
			display:inline-block;
			.schoolAddress {
				color:#FFFFFF;
				padding:0 10px;
				line-height: 20px;
				a {
					color:#FFFFFF;
					font-size:1em;
				}
			}
		}
		.footerRight {
			width:calc(50% - 20px);
			padding:0 120px 0 10px;
			display:inline-block;
			text-align:right;
			color:#FFFFFF;
			a {
				color:#FFFFFF;
			}
		}
	}
	
}


/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToTop {

	width: 100px;
	height: 85px;
	bottom: 15px;
	right: 30px;
	padding: 10px;
	z-index: 99;
	cursor: pointer;
	
	position: absolute;
	.icon {
		width: 50px;
		height: 50px;
		left:50%;
		@include transform( translate(-50%, -50%));
		/*@include transform-origin(25% 25%);*/
		background-image:url(../images/upArrow3.svg);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		position: absolute;
		/*background-color:#FFFFFF;*/
	}
	.text {
		color:#FFFFFF;
		margin-top:35px;
		text-align:center;
	}
	&:hover .icon {
		background-image:url(../images/upArrow3hover.svg);
	}
}
.flexslider .flex-control-nav{
	display: none !important;
}

.Loading{
	opacity: 0;
}
#content{
	min-height: 80vh;
}

/********/



.sideLinks{
	position: absolute;
	z-index: 999;
	right: 0px;
	top: 50%;
	@include transform(translateY(-50%));
	a{
		display: block;
		clear: both;
		float: right;
		background-color: $themeColor;
		padding: 20px;
		color: #fff;
		font-weight: 700;
		font-size: 1.4em;
		max-width: 160px;
    	line-height: 1em;
		margin: 6px 0px;
		min-width: 160px;
		@include transition($t1);
		&:hover{
			min-width: 180px;
			padding: 20px 40px 20px 20px;
		}
	}
	/*.link-1{
		background-color: #c0d767;
	}
	.link-2{
		background-color: #21873c;
	}
	.link-3{
		background-color: #a0ca29;
	}
	.link-4{
		background-color: #25ac48;
	}
	.link-5{
		background-color: #c0d767;
	}
	.link-6{
		background-color: #72be4e;
	}*/
}




a.important{
	color: #c00;
	font-weight: bold;
}

.tickerContainer{
	clear: both;
	position: relative;
	bottom: 0;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 46px;
	background-color: #0c3d89;
}



.tickertape{
	float: left;
	position: relative;
	overflow: hidden;
}
 
.tickertape section,
.tickertape span{
	font-weight: lighter;
}

.tickertape a{
	color: #fff;
	font-weight: lighter;
}

.tickertape section{
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 15px 0;
	position: relative;
	text-align: center;
	color: #fff;
	letter-spacing: 1px;
	font-size: 14px;
}

.ticker{
	max-height: 46px;
	width: 100%;
	    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 46px;
}